import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'

import { DurationTimer } from '../../../components'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { OtpResendMessage, PaymentContainer } from '../../CheckoutPayment/Checkout.styled'

import { actions, globalSettings } from 'mmfintech-checkout-commons'
import { checkSingleValue, tr, usePolling } from 'mmfintech-commons'

import { ThunkDispatch } from 'redux-thunk'
import { TwoFactorTypeEnum } from 'mmfintech-commons-types'

const getOptTitle = (twoFactorType: TwoFactorTypeEnum) => {
  switch (twoFactorType) {
    case TwoFactorTypeEnum.SMS:
      return 'SMS verification'

    case TwoFactorTypeEnum.EMAIL:
      return 'Email verification'

    case TwoFactorTypeEnum.TOTP:
      return 'Google Authenticator verification'

    case TwoFactorTypeEnum.SMART_ID:
      return 'Smart ID verification'

    default:
      return 'Email verification'
  }
}

const getOptSubtitle = (twoFactorType: TwoFactorTypeEnum) => {
  switch (twoFactorType) {
    case TwoFactorTypeEnum.SMS:
      return tr('CHECKOUT.PAYMENT.OTP_SUBTITLE', "Please, enter the verification code that we've sent to:")

    case TwoFactorTypeEnum.EMAIL:
      return tr('CHECKOUT.PAYMENT.OTP_SUBTITLE', "Please, enter the verification code that we've sent to:")

    case TwoFactorTypeEnum.TOTP:
      return tr('CHECKOUT.PAYMENT.OTP_SUBTITLE_TOTP', 'Please, enter the verification code:')

    case TwoFactorTypeEnum.SMART_ID:
      return tr(
        'CHECKOUT.PAYMENT.OTP_SUBTITLE_SMART_ID',
        'We’ve sent a notification to your device. Please check to verify it’s you.'
      )

    default:
      return tr('CHECKOUT.PAYMENT.OTP_SUBTITLE', "Please, enter the verification code that we've sent to:")
  }
}

interface WalletCheckoutOtpProps {
  onSuccess: (response: any) => void
  onCancel?: any
  wallet?: boolean
}

export const WalletCheckoutOtp = ({ onSuccess, onCancel, wallet }: WalletCheckoutOtpProps) => {
  const { challenge, resendError, resendFetching, verifyError, verifyFetching, smartIdPolling } = useSelector(
    ({ auth: { challenge, resendError, resendFetching, verifyError, verifyFetching, smartIdPolling } }) => ({
      challenge,
      resendError,
      resendFetching,
      verifyError,
      verifyFetching,
      smartIdPolling
    }),
    shallowEqual
  )

  const [expired, setExpired] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [verificationCode, setVerificationCode] = useState('')

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { challengeId, sentTo, twoFactorType, otpLength } = challenge || {}
  const pool = usePolling(2000, 'smartId.polling', () =>
    dispatch(actions.auth.challengeSmartIdSolved(challengeId, onSuccess, startPollingAgain))
  )

  const startPollingAgain = () => {
    pool.start()
  }

  const codeSize = useMemo(() => (otpLength > 0 ? otpLength : globalSettings.otpCodeLength), [otpLength])

  const handleChange = ({ target }) => {
    const newValue = checkSingleValue(target.value, { validation: 'numeric' })
    setVerificationCode(newValue)
  }

  useEffect(() => {
    if (twoFactorType === TwoFactorTypeEnum.SMART_ID) {
      void dispatch(actions.auth.challengeSmartIdSolved(challengeId, onSuccess))
    }
  }, [twoFactorType])

  useEffect(() => {
    if (twoFactorType === TwoFactorTypeEnum.SMART_ID && smartIdPolling) {
      startPollingAgain()
    }
  }, [twoFactorType, smartIdPolling])

  const handleResend = () => {
    if (wallet) {
      void dispatch(
        actions.auth.challengeResendWallet(challengeId, () => {
          setAttempts(0)
          setVerificationCode('')
        })
      )
    } else {
      void dispatch(
        actions.auth.challengeResend(challengeId, () => {
          setAttempts(0)
          setVerificationCode('')
        })
      )
    }
  }

  useEffect(() => {
    if (verificationCode.length === codeSize && attempts < globalSettings.otpMaxAttempts) {
      setAttempts(attempts + 1)
      if (wallet) {
        void dispatch(actions.auth.challengeVerifyWallet(challengeId, verificationCode, onSuccess))
      } else {
        void dispatch(actions.auth.challengeVerify(challengeId, verificationCode, onSuccess))
      }
    }
    // eslint-disable-next-line
  }, [verificationCode])

  useEffect(() => {
    return () => {
      dispatch(actions.auth.challengeCleanup())
    }
  }, [dispatch])

  return (
    <PaymentContainer className='vertically-centered' data-test='wallet-checkout-otp'>
      <div className='otp-title'>{getOptTitle(twoFactorType)}</div>
      <div className='otp-subtitle'>
        {getOptSubtitle(twoFactorType)}
        {sentTo && <span>{sentTo}</span>}
      </div>

      <ErrorDisplay error={verifyError} />

      {resendFetching ? null : resendError || twoFactorType === TwoFactorTypeEnum.SMART_ID ? (
        <>
          <ErrorDisplay error={resendError} />

          {onCancel && (
            <Button
              type='button'
              color='secondary'
              text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
              onClick={onCancel}
              data-test='button-cancel'
            />
          )}
        </>
      ) : (
        <>
          <div className='text-center'>
            <input
              id='otp'
              type='tel'
              className={cn({
                complete: verificationCode?.length === codeSize,
                wrong: verificationCode?.length === codeSize && verifyError != null
              })}
              autoComplete='off'
              onChange={handleChange}
              value={verificationCode}
              maxLength={codeSize}
              autoFocus
              placeholder={tr('CHECKOUT.OTP.ENTER_CODE', 'Enter Code')}
              disabled={expired || attempts >= globalSettings.otpMaxAttempts}
              data-test='tel-input'
            />
          </div>
          {twoFactorType !== TwoFactorTypeEnum.TOTP && (
            <OtpResendMessage>
              {attempts < globalSettings.otpMaxAttempts ? (
                <>
                  {tr('CHECKOUT.OTP.HAVENT_RECEIVE', "Haven't received it?")}
                  <span onClick={handleResend}>{tr('CHECKOUT.OTP.RESEND_CODE', 'Re-send code')}</span>
                </>
              ) : (
                tr('CHECKOUT.OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
              )}
            </OtpResendMessage>
          )}

          <div className='mt-3'>
            {onCancel && (
              <Button
                type='button'
                color='primary'
                text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
                disabled={verifyFetching}
                onClick={onCancel}
                data-test='button-cancel'
              />
            )}
          </div>
        </>
      )}

      {attempts < globalSettings.otpMaxAttempts && !resendFetching && <DurationTimer setCustomExpired={setExpired} />}
    </PaymentContainer>
  )
}
