import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { actions } from 'mmfintech-checkout-commons'

import { Button } from 'mmfintech-portal-commons'
import { TailSpin } from 'react-loader-spinner'
import { MerchantPane } from '../../components'
import { WalletCheckoutOtp } from './WalletCheckoutOtp'
import { WalletCheckoutLogin } from './WalletCheckoutLogin'
import { WalletCheckoutAccount } from './WalletCheckoutAccount'
import { WalletCheckoutPreview } from './WalletCheckoutPreview'
import { PaymentContainer } from '../CheckoutPayment/Checkout.styled'

import { ThunkDispatch } from 'redux-thunk'
import { PaymentMethod } from 'mmfintech-commons-types'
import { tr } from 'mmfintech-commons'

interface WalletCheckoutProps {
  sessionId: string
  handlePay: (accountId: number) => void
  onCancel: () => void
  paymentMethod: PaymentMethod
  hasStorageAccess: boolean
  setStorageAccess: (enable: boolean) => void
}

enum CheckoutSteps {
  LOADING = 'LOADING',
  OPEN_POPUP = 'OPEN_POPUP',
  REQUEST_ACCESS = 'REQUEST_ACCESS',
  LOGIN = 'LOGIN',
  OTP = 'OTP',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  PREVIEW = 'PREVIEW'
}

export const WalletCheckout = (props: WalletCheckoutProps) => {
  const { sessionId, handlePay, onCancel, paymentMethod, hasStorageAccess, setStorageAccess } = props

  const { logo, session } = useSelector(
    ({ checkout: { logo, session } }) => ({
      logo,
      session
    }),
    shallowEqual
  )

  const [step, setStep] = useState<CheckoutSteps>(CheckoutSteps.LOADING)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleCancel = () => {
    void dispatch(actions.auth.logout())
    onCancel()
  }

  const handleLoginResponse = (response: any) => {
    setStep(response?.data?.challenge ? CheckoutSteps.OTP : CheckoutSteps.SELECT_ACCOUNT)
  }

  const requestAccess = () => {
    const noAccess = () => {
      console.error('Cannot obtain storage access')
      onCancel()
    }

    const continueWithPayment = () => {
      setStorageAccess(true)
      void dispatch(
        actions.auth.auth(
          () => setStep(CheckoutSteps.SELECT_ACCOUNT),
          () => setStep(CheckoutSteps.LOGIN)
        )
      )
    }

    if (typeof document.requestStorageAccess === 'function') {
      document
        .requestStorageAccess()
        .then(
          () => continueWithPayment(),
          () => noAccess()
        )
        .catch(() => {
          noAccess()
        })
    } else {
      continueWithPayment()
    }
  }

  const openPopup = () => {
    setStep(CheckoutSteps.LOADING)
    const handler = window.open(
      `${window.origin}/auth/sessionId/${sessionId}`,
      '_blank',
      'popup=1,location=0,toolbar=0,menubar=0,status=0' //,width=340,height=350
    )
    if (handler) {
      const interval = setInterval(() => {
        if (handler.closed) {
          clearInterval(interval)
          //@ts-ignore
          if (handler.userLoggedIn) {
            setStep(CheckoutSteps.REQUEST_ACCESS)
          } else {
            onCancel()
          }
        }
      }, 500)
    } else {
      onCancel()
    }
  }

  useEffect(() => {
    if (hasStorageAccess) {
      setStep(CheckoutSteps.LOGIN)
    } else {
      setStep(CheckoutSteps.OPEN_POPUP)
    }

    return () => {
      setStep(CheckoutSteps.LOADING)
    }
  }, [])

  return (
    <>
      {step !== CheckoutSteps.LOADING && <MerchantPane session={session} logo={logo} />}

      {step == CheckoutSteps.LOADING && (
        <div className='mb-4 mt-4'>
          <TailSpin width='8rem' height='8rem' color='white' />
        </div>
      )}

      {step == CheckoutSteps.OPEN_POPUP && (
        <PaymentContainer data-test='wallet-checkout-request-access'>
          <div className='inner-subtitle'>{tr('CHECKOUT.WALLET_PAYMENT.OPEN_POPUP_TITLE', 'Sign in')}</div>
          <p>
            {tr(
              'CHECKOUT.WALLET_PAYMENT.OPEN_POPUP',
              'In order to continue with Jeton, please click the button below. A new popup window will appear.'
            )}
          </p>

          <div className='mt-4'>
            <Button
              type='button'
              color='alternative'
              className='mb-1'
              text={tr('CHECKOUT.BUTTONS.CONTINUE', 'Continue')}
              onClick={openPopup}
            />
            <Button
              type='button'
              color='secondary'
              text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
              onClick={onCancel}
              data-test='button-back'
            />
          </div>
        </PaymentContainer>
      )}

      {step == CheckoutSteps.REQUEST_ACCESS && (
        <PaymentContainer data-test='wallet-checkout-request-access'>
          <div className='inner-subtitle'>{tr('CHECKOUT.WALLET_PAYMENT.ACCESS_REQUEST_TITLE', 'Cookie consent')}</div>
          <p>
            {tr(
              'CHECKOUT.WALLET_PAYMENT.ACCESS_REQUEST',
              'Our website use cookies to provide safe user experience. Please click the button below to accept.'
            )}
          </p>

          <div className='mt-4'>
            <Button
              type='button'
              color='alternative'
              className='mb-1'
              text={tr('CHECKOUT.BUTTONS.CONTINUE', 'Continue')}
              onClick={requestAccess}
            />
            <Button
              type='button'
              color='secondary'
              text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
              onClick={onCancel}
              data-test='button-back'
            />
          </div>
        </PaymentContainer>
      )}

      {step === CheckoutSteps.LOGIN && (
        <WalletCheckoutLogin
          sessionId={sessionId}
          session={session}
          onSuccess={handleLoginResponse}
          onCancel={onCancel}
        />
      )}

      {step === CheckoutSteps.OTP && (
        <WalletCheckoutOtp onSuccess={() => setStep(CheckoutSteps.SELECT_ACCOUNT)} onCancel={onCancel} />
      )}

      {step === CheckoutSteps.SELECT_ACCOUNT && (
        <WalletCheckoutAccount
          onSuccess={() => setStep(CheckoutSteps.PREVIEW)}
          onCancel={handleCancel}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          paymentMethod={paymentMethod}
        />
      )}

      {step === CheckoutSteps.PREVIEW && (
        <WalletCheckoutPreview
          onCancel={() => setStep(CheckoutSteps.SELECT_ACCOUNT)}
          handlePay={() => handlePay(selectedAccount.id)}
        />
      )}
    </>
  )
}
