import styled from 'styled-components'

export const CheckoutContent = styled.div`
  width: 100%;
  max-width: 50rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  .error-display-wrapper {
    svg {
      margin-right: 0.8rem;
    }
  }
`

export const MessageWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  margin-bottom: 2rem;
  padding: 5rem;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;

  .content {
    text-align: center;
    margin-top: 3rem;
    width: 100%;

    button {
      width: 100%;
      max-width: 30rem;
    }

    .timer {
      color: #939ea7;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 5rem;
      margin-top: 1rem;
    }
  }
`
