import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { isJetonWallet } from 'mmfintech-checkout-commons'
import { isValidArray, tr } from 'mmfintech-commons'

import { MethodPane } from './MethodPane'
import { MethodButton } from './MethodButton'
import { SelectMethodContainer, SelectMethodEmpty, SelectMethodStyled, SelectMethodTitle } from './SelectMethod.styled'

import { PaymentOptionResponse } from 'mmfintech-commons-types'

type SelectMethodProps = {
  className?: string
  method?: string
  setMethod?: (method: string) => void
  methodsLoading?: boolean
  countryCode?: string
  special?: boolean
}

export const SelectMethod = ({
  className,
  method,
  setMethod,
  countryCode,
  methodsLoading,
  special
}: SelectMethodProps) => {
  const { checkoutOptions } = useSelector(
    ({ checkout: { checkoutOptions } }) => ({
      checkoutOptions
    }),
    shallowEqual
  )

  const [options, setOptions] = useState<PaymentOptionResponse[]>(null)

  useEffect(() => {
    if (isValidArray(checkoutOptions) && special) {
      setOptions(checkoutOptions.filter(option => !isJetonWallet(option.paymentMethod)))
    } else {
      setOptions(checkoutOptions)
    }
    // eslint-disable-next-line
  }, [checkoutOptions, special])

  useEffect(() => {
    if (Array.isArray(options) && options.length === 1) {
      setMethod(options[0].paymentMethod)
    }
    // eslint-disable-next-line
  }, [options])

  if (isValidArray(options)) {
    if (options.length > 1) {
      return (
        <SelectMethodStyled>
          <SelectMethodTitle>{tr('CHECKOUT.SELECT.METHOD.PAYMENT_WITH', 'Payment With')}</SelectMethodTitle>
          <SelectMethodContainer className={className}>
            {options.map(option => (
              <MethodButton
                key={option.paymentMethod}
                name={option.paymentMethod}
                selected={option.paymentMethod === method}
                setSelected={setMethod}
              />
            ))}
          </SelectMethodContainer>
        </SelectMethodStyled>
      )
    }

    return (
      <SelectMethodStyled>
        <SelectMethodTitle>{tr('CHECKOUT.SELECT.METHOD.PAYING_WITH', 'Paying With')}</SelectMethodTitle>
        <MethodPane method={method} />
      </SelectMethodStyled>
    )
  }

  return countryCode && !methodsLoading ? (
    <SelectMethodStyled>
      <SelectMethodEmpty>{tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}</SelectMethodEmpty>
    </SelectMethodStyled>
  ) : null
}
